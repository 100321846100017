html {
  background-color: #282c34;
  color: white;
  font-size: calc(10px + 2vmin);
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

footer {
  margin-top: 1em;
  margin-bottom: 1em;
}

footer a {
  color: white;
}

.App {
  font-family: 'Courier New', Courier, monospace;
  text-align: center;
}

.App-container {
}

.commandList {
  display: flex;
  flex-wrap: wrap;
}

.command {
  word-wrap: none;
}

.icon {
  border-radius: 0.125em;
  border: 2px solid white;
  margin: 1em;
  padding-left: 0.25em;
  padding-right: 0.25em;
  width: content;
}

.line {
  white-space: normal;
}

.terminal {
  border-radius: 0.125em;
  border: 2px solid white;
  min-height: 25em;
  margin: 2em auto 1em;
  max-width: fit-content;
  padding: 0.1em 0.5em;
  padding-right: 2em;
  text-align: left;
  white-space: normal;
  width: 80%;
}

.terminal input {
  background-color: inherit;
  border: none;
  color: white;
  font-family: inherit;
  font-size: inherit;
  -webkit-appearance: textfield;
}

.terminal input:focus {
  outline: none;
}

.index {
  color: #0070ff;
}
